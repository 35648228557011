'use strict';

angular.module('appApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('submitIngredient', {
        url: '/submitIngredient/:ingredient?pageSize&pageNumber&filtered&multilingual',
        templateUrl: 'app/submitIngredient/submitIngredient.html',
        controller: 'SubmitIngredientCtrl',
        authenticate: true
      });
  });
